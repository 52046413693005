import React from "react";
import {
  breakpoints,
  CenteredContentWrapper,
  EmphasizedText,
  Subheading,
} from "@xolvio/xolvio-ui";
import { pageData } from "../pages-content/homepage";
import { graphql, StaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { SectionWrapper, TextBodyStyled, SectionHeaderStyled } from "./common";
import styled from "styled-components";

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const GreenfieldProjectsSection = () => (
  <StaticQuery
    query={graphql`
      query {
        img: file(relativePath: { eq: "flows@2x.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1700) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `}
    render={(data) => {
      return (
        <>
          <CenteredContentWrapper>
            <SectionWrapper>
              <FlexColumn>
                <BackgroundImage
                  fluid={data.img.childImageSharp.fluid}
                  durationFadeIn={200}
                  style={{
                    height: "423px",
                    maxHeight: "80vw",
                    backgroundSize: "contain",
                  }}
                />
              </FlexColumn>

              <FlexColumn>
                <Subheading>{pageData.greenfieldProjects.subHeading}</Subheading>
                <SectionHeaderStyled style={{ marginBottom: 0, maxWidth: 573, fontSize: '36px' }}>
                  {pageData.greenfieldProjects.header.map((txt) => (
                    <div key={txt}>
                      <EmphasizedText
                        text={txt}
                        highlight={pageData.greenfieldProjects.highlight}
                      />
                    </div>
                  ))}
                </SectionHeaderStyled>
                <TextBodyStyled>{pageData.greenfieldProjects.body}</TextBodyStyled>
              </FlexColumn>
            </SectionWrapper>
          </CenteredContentWrapper>
        </>
      );
    }}
  />
);
