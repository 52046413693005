import React from "react";
import styled from "styled-components";
import {
  CenteredContentWrapper,
  device,
  EmphasizedText,
  PageHeader,
} from "@xolvio/xolvio-ui";
import { pageData } from "../pages-content/homepage";
import { graphql, StaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import {HeroBackgroundImage, SectionWrapper, TextBodyStyled} from './common'
import { ButtonPrimary } from "@xolvio/xolvio-ui";

export const Hero = () => (
  <StaticQuery
    query={graphql`
      query {
        hero: file(relativePath: { eq: "specs-intro@2x.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1700) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        heroBg: file(relativePath: { eq: "top-bg@2x.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1700) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `}
    render={(data) => {
      return (
        <>
          <HeroBackgroundImage
            fluid={data.heroBg.childImageSharp.fluid}
            durationFadeIn={200}
            style={{
              position: "absolute", // this neeeds to be set here
              width: "200%"
            }}
          />

          <CenteredContentWrapper>
            <HeroContentWrapper>
              <HeroLeft>
                <HeroHeader>
                  {Array.isArray(pageData.hero.header) ? (
                    <>
                      {pageData.hero.header.map((line) => (
                        <div key={line}>
                          <EmphasizedText
                            text={line}
                            highlight={pageData.hero.highlight}
                          />
                        </div>
                      ))}
                    </>
                  ) : (
                    <EmphasizedText
                      text={pageData.hero.header}
                      highlight={pageData.hero.highlight}
                    />
                  )}
                </HeroHeader>
                <TextBodyStyled>{pageData.hero.body}</TextBodyStyled>
                <ButtonPrimary>{pageData.hero.button}</ButtonPrimary>
              </HeroLeft>

              <BackgroundImage
                fluid={data.hero.childImageSharp.fluid}
                durationFadeIn={200}
                style={{
                  height: "423px",
                  maxHeight: "80vw",
                  backgroundSize: "contain",
                }}
              />
            </HeroContentWrapper>
          </CenteredContentWrapper>
        </>
      );
    }}
  />
);

const HeroContentWrapper = styled(SectionWrapper)`
  margin-top: 50px;
  flex-direction: column-reverse;

  @media ${device.tabletVertical} {
    flex-direction: row;
    margin-top: 180px;
  }
`;

const HeroLeft = styled.div`
  text-align: center;
  @media ${device.tablet} {
    text-align: left;
  }
`;

const HeroHeader = styled(PageHeader)`
  margin-bottom: 0;
  letter-spacing: -0.34px;

  @media ${device.mobile} {
    font-size: 36px;
  }

  @media ${device.tablet} {
    font-size: 54px;
  }
`;
