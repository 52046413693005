import React from "react";
import styled from "styled-components";
import { CenteredContentWrapper, device } from "@xolvio/xolvio-ui";
import { graphql, StaticQuery } from "gatsby";
import GatsbyImage from "gatsby-image";

const Image = ({ img }) => (
  <GatsbyImage
    durationFadeIn={200}
    fluid={img.childImageSharp.fluid}
    style={{
      width: "100%",
      height: "auto",
      boxShadow: "0 0 15px #ccc",
    }}
  />
);

export const ScreenshotSlider = () => (
  <StaticQuery
    query={graphql`
      query {
        img1: file(relativePath: { eq: "Requirements-to-Spec.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        img2: file(relativePath: { eq: "Spec to Code.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        img3: file(relativePath: { eq: "Code-To-Cloud.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `}
    render={(data) => (
      <CenteredContentWrapper>
        <MainContainer>
          <Row reversedOnMobile>
            <ContentCol>
              <ContentContainer>
                <SubHeader>STEP 1: 50 MIN.</SubHeader>
                <Header>Requirements to specs</Header>
                <Description>
                  Define your requirements in a doc with rich text, images, and
                  embedded content. Pick a portion of your doc for the AI Assist
                  to turn it into an editable spec suggestion. As your
                  requirements evolve, update your specs accordingly using
                  changesets to stay on track.
                </Description>
              </ContentContainer>
            </ContentCol>
            <ImageCol>
              <Image img={data.img1} />
            </ImageCol>
          </Row>

          <Row>
            <ImageCol>
              <Image img={data.img2} />
            </ImageCol>
            <ContentCol>
              <ContentContainer>
                <SubHeader>STEP 2: 50 MIN.</SubHeader>
                <Header>Specs to code</Header>
                <Description>
                  Use the AI-assisted Domain Designer to turn a natural language
                  domain description from your document into a visual business
                  process model. With the help of AI suggestions, define the
                  specs and business rules for the relevant nodes, then
                  generate, review and accept the code.
                </Description>
              </ContentContainer>
            </ContentCol>
          </Row>

          <Row reversedOnMobile>
            <ContentCol>
              <ContentContainer>
                <SubHeader>STEP 3: 5 MIN.</SubHeader>
                <Header>Code to cloud</Header>
                <Description>
                  Once your code is polished, simply deploy to our secure cloud
                  at the click of a button. Alternatively, deploy to your own
                  infrastructure of choice. Either way, your new event-driven
                  backend is ready for integration by way of a GraphQL API.
                </Description>
              </ContentContainer>
            </ContentCol>
            <ImageCol>
              <Image img={data.img3} />
            </ImageCol>
          </Row>
        </MainContainer>
      </CenteredContentWrapper>
    )}
  />
);

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 80px 0;
  gap: 30px;

  @media ${device.phone} {
    gap: 70px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 480px;
  gap: 60px;

  @media ${device.phone} {
    flex-direction: ${(props) =>
      props.reversedOnMobile ? "column-reverse" : "column"};
    gap: 20px;
  }
`;

const Col = styled.div`
  display: flex;
  align-items: center;
`;

const ImageCol = styled(Col)`
  flex: 3;
  justify-content: center;
`;

const ContentCol = styled(Col)`
  flex: 2;
`;

const ContentContainer = styled.div``;

const SubHeader = styled.div`
  font-weight: bold;
  font-size: 12px;
  color: #56bf98;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const Header = styled.div`
  font-weight: bold;
  font-size: 36px;
  color: #3c3f3e;
  margin-top: 16px;

  @media ${device.mobile} {
    font-size: 26px;
  }
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 18px;
  color: #6b726f;
  letter-spacing: -0.2px;
  line-height: 1.56;
  margin-top: 24px;

  @media ${device.mobile} {
    font-size: 16px;
  }
`;
