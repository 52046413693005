import React from "react";
import {
  breakpoints,
  CenteredContentWrapper,
  EmphasizedText,
  Subheading,
} from '@xolvio/xolvio-ui'
import { pageData } from "../pages-content/homepage";
import { graphql, StaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import {
  SectionWrapper,
  TextBodyStyled,
  SectionHeaderStyled,
} from "./common";
import styled from 'styled-components'

export const RefactoringSection = () => (
  <StaticQuery
    query={graphql`
      query {
        img: file(relativePath: { eq: "specs-ai@4x.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1700) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `}
    render={(data) => {
      return (
        <>
          <CenteredContentWrapper>
            <SectionWrapperReversedOnMobile>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div>
                  <Subheading>{pageData.refactoring.subHeading}</Subheading>
                  <SectionHeaderStyled style={{ marginBottom: 0, maxWidth: 573, fontSize: '36px' }}>
                    {pageData.refactoring.header.map((txt) => (
                      <div key={txt}>
                        <EmphasizedText
                          text={txt}
                          highlight={pageData.refactoring.highlight}
                        />
                      </div>
                    ))}
                  </SectionHeaderStyled>
                  <TextBodyStyled>{pageData.refactoring.body}</TextBodyStyled>
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <BackgroundImage
                  fluid={data.img.childImageSharp.fluid}
                  durationFadeIn={200}
                  style={{
                    height: "423px",
                    maxHeight: "80vw",
                    backgroundSize: "contain",
                  }}
                />
              </div>
            </SectionWrapperReversedOnMobile>
          </CenteredContentWrapper>
        </>
      );
    }}
  />
);

const SectionWrapperReversedOnMobile = styled(SectionWrapper)`
  @media (max-width: ${breakpoints.tabletVertical.start - 1}px) {
    flex-direction: column-reverse;
  }
`;
