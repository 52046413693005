import styled from "styled-components";
import { device } from "@xolvio/xolvio-ui";

export const Anchor = styled.a`
  position: relative;
  top: ${props => props.topMobile || "-90"}px;
  @media ${device.tablet} {
    position: relative;
    top: ${props => props.topTablet || 0}px;
  }
`;
