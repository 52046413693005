import React from "react";
import {SectionHeaderStyled, SectionHeaderWrapper, Spacing, TextBodyStyled} from './common'
import { Hero } from "./Hero";
import { WhyXspecsSection } from "./WhyXspecsSection";
import { Anchor } from "./Anchor";
import {RefactoringSection} from './RefactoringSection'
import {device, SectionHeader, Subheading, TextBody} from '@xolvio/xolvio-ui'
import styled from 'styled-components'
import {JoinTheBetaButton} from './JoinTheBetaButton'
import {GreenfieldProjectsSection} from './GreenfieldProjectsSection'
import { pageData } from "../pages-content/homepage";
import {ScreenshotSlider} from './ScreenshotSlider'

const TopWrapper = styled(SectionHeaderWrapper)`
  padding: 24px;
  width: auto;

  @media ${device.desktop} {
    margin-bottom: 48px;
    margin-top: 48px;
  }
`;

export const Homepage = () => {

  return (
    <>
      <Hero />
      <Spacing />

      <Anchor id={"how-it-works"} topTablet={-80} />
      <TopWrapper>
        <Subheading>{pageData.benefits.subHeading}</Subheading>
        <SectionHeaderStyled>{pageData.benefits.header}</SectionHeaderStyled>
        <TextBodyStyled style={{ marginBottom: 8, maxWidth: 850, margin: "auto", textAlign: "center" }}>
          {pageData.benefits.body}
        </TextBodyStyled>
      </TopWrapper>

      <ScreenshotSlider />
      <Spacing />

      <Anchor id={"use-cases"} topTablet={-80} />
      <TopWrapper>
        <Subheading>USE CASES</Subheading>
        <SectionHeaderStyled>For new & existing systems alike</SectionHeaderStyled>
        <TextBodyStyled style={{ marginBottom: 8, maxWidth: 850, margin: "auto", textAlign: "center" }}>
          Whether you’re building something new or working on an existing system, XSpecs fits right in.
        </TextBodyStyled>
      </TopWrapper>

      <GreenfieldProjectsSection />
      <RefactoringSection />
      <WhyXspecsSection />
      <Spacing />

      <Wrapper>
        <ContentWrapper>
          <Subheading>LET’S BUILD MORE WITH LESS TOGETHER</Subheading>
          <SectionHeader>Become Spec-Driven</SectionHeader>
          <ContentTextBody>Sign up for the beta waitlist to get notified when XSpecs goes live in the coming weeks.</ContentTextBody>
        </ContentWrapper>
        <ButtonWrapper>
          <JoinTheBetaButton />
        </ButtonWrapper>
      </Wrapper>
      <Spacing mobile={20} tablet={30} />
    </>
  );
};

const Wrapper = styled.div`
  background-color: #f4f7f6;
  display: flex;
  flex-direction: column;
  padding: 120px 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const ContentWrapper = styled.div`
  text-align: center;
  margin: 0 auto;
  padding: 0 24px;
  
  > * {
    text-align: center;
    max-width: 750px;
  }

  @media ${device.tablet} {
    margin: 0 auto;
  }
`;

const ContentTextBody = styled(TextBody)`
  text-align: center;
  margin: 0 auto;
  max-width: 750px;
  padding-bottom: 24px;
  
  @media ${device.mobile} {
    text-align: left;
  }
`
